.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes AnimatedBG {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimatedBG {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimatedBG {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.animatedBG {
  position: relative;
  -webkit-animation: AnimatedBG 14s ease infinite;
  -moz-animation: AnimatedBG 14s ease infinite;
  animation: AnimatedBG 14s ease infinite;
  box-shadow: 5px 4px 13px 0px rgba(0,0,0,0.32);
  background: linear-gradient(294deg, #4672ff, #120ebb, #691987, #120ebb, #468bff);
}

body {
  font-family: 'Poppins', sans-serif !important;
}
